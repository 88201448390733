import styled from 'styled-components'
import { color, space, layout, flexbox, position, border, shadow } from 'styled-system'

const Box = styled.div`
  ${flexbox}
  ${color}
  ${space}
  ${layout}
  ${position}
  ${border}
  ${shadow}
`

const Flex = styled.div`
  display: flex;
  ${flexbox}
  ${space}
  ${layout}
  ${position}
  ${border}
  ${shadow}
  ${color}
`

const ResponsiveImg = styled.img`
  ${layout}
`

export {
  Box,
  Flex,
  ResponsiveImg,
}
