import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { Button, Carousel } from 'react-bootstrap'

import theme from './theme'
import './assets/styles/App.scss'

import Feature from './components/feature'
import NavBar from './components/nav-bar'
import Faq from './components/faq'
import Footer from './components/footer'
import { Box, Flex } from './components/layout'
import { Container } from 'react-bootstrap'
import { H1, H2, Label } from './components/text'
import Community from './components/community'
import Roadmap from './components/roadmap'
import Team from './components/team'
import { Image } from './components/msc'
import previewCard1 from './assets/images/05May2017.svg'
import previewCard2 from './assets/images/25Jun2017.svg'
import previewCard3 from './assets/images/10Nov2021.svg'
import CountDown from './components/countdown'

const HeroWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.colors.primary};
  display: flex;
  position: relative;

  @media screen and (min-width: 40em) {
    background-attachment: fixed;
    background-position: center;
  }
`

const HeroContent = styled(Container)`
  width: 100%;
  height: 100%;
`

const HeroTextContainer = styled(HeroContent)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 16px;
`

const Wrapper = styled(Box)`
  background: linear-gradient(0deg, #000428, #004e92);
`

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
  object-fit: cover;
`

const Title = styled(H1)`
  color: #fff6a9;

  text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;

  @-webkit-keyframes blink {
    20%,
    24%,
    55% {
      color: #111;
      text-shadow: none;
    }
  
    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
      text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
      color: #fff6a9;
    }
  }
  
  @keyframes blink {
    20%,
    24%,
    55% {
      color: #111;
      text-shadow: none;
    }
  
    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
      text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
      color: #fff6a9;
    }
  }
  
`

const Title2 = styled(H1)`
  color: #ffa9a9;

  text-shadow: 0 0 5px #ff4600, 0 0 15px #ff003e, 0 0 20px #ff0000, 0 0 40px #ff0049, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;

  @-webkit-keyframes blink2 {
    20%,
    24%,
    55% {
      color: #111;
      text-shadow: none;
    }
  
    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
      text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
      color: #fff6a9;
    }
  }
  
  @keyframes blink2 {
    20%,
    24%,
    55% {
      color: #111;
      text-shadow: none;
    }
  
    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
      text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
      color: #fff6a9;
    }
  }
  
`

const Square = styled(Box)`
  position: relative;
  width: 40vh;
  height: 40vh;
  margin-top: 0vh;

  @media (min-width: 768px) and (max-width: 1199.98px) {
    width: 50vh;
    height: 50vh;
  }

  @media (min-width: 1200px) {
    width: 80vh;
    height: 80vh;
  }

  .carousel-inner {
    overflow: visible;
  }
`;

const Caption = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(0, 0, 0, 0.3);
  border-radius: 5px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1em;

  @media screen and (min-width: 40em) {
    bottom: -10px;
    font-size: 1.5em;
  }
`;

const SaleTimeBlock = styled(Flex)`
  background: linear-gradient(to right, #00042833, #004e9233);
  border-radius: 20px;
  padding: 16px;
`

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <HeroWrapper>
          <Wrapper position="absolute" top={0} left={0} right={0} bottom={0}>
            <HeroContent>
              <Flex pt={100} flexDirection="column" alignItems="center" width="100%" height="100%">
                <Flex flexDirection="column" alignItems="center" display={["flex", "none"]}>
                  <CountDown />
                </Flex>
                <Flex flex={1} width="100%" justifyContent="center" alignItems="center" flexDirection={["column-reverse", "column-reverse", "row"]}>
                  <Flex flex={1} height={["80%", "60vh"]} width="100%" flexDirection="column" zIndex="999" justifyContent={["center", "space-between"]} pt={[0, 0]}>
                    <Flex flexDirection="column" alignItems="center">
                      <Flex display={["none", "flex"]}><CountDown /></Flex>
                      <SaleTimeBlock width={["100%", "80%"]} mb={[2, 6]}>
                        <Flex flexDirection="column" flex={1} alignItems="center">
                          <Label textStyle="subTextBase" color="secondary">PRESALE</Label>
                          <Label textStyle="subTextBase" color="white">19th March</Label><Label textStyle="subTextBase" color="white">1600 UTC</Label>
                        </Flex>
                        <Flex flexDirection="column" flex={1} alignItems="center">
                          <Label textStyle="subTextBase" color="secondary">Public sale</Label>
                          <Label textStyle="subTextBase" color="white">LIVE</Label>
                        </Flex>
                      </SaleTimeBlock>
                    </Flex>
                    <Flex flexDirection="column" alignItems="center">
                      <Title color="secondary" fontFamily="Upbolters" fontSize={[5, 6]} display={["none", "block"]}>Doji x Ethereum</Title>
                      <Flex mt={4} mb={2} alignItems="center">
                        <Label fontSize={5} color="white" mr={4}>Join us</Label>
                        <Community />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex flex={1} justifyContent="flex-end" height={["100%"]} alignItems="center">
                    <Square my={[0, 6, 0]}>
                      <Carousel fade interval={10000} indicators={false}>
                        <Carousel.Item><Image width="100%" src={previewCard1} /></Carousel.Item>
                        <Carousel.Item><Image width="100%" src={previewCard2} /></Carousel.Item>
                        <Carousel.Item><Image width="100%" src={previewCard3} /></Carousel.Item>
                      </Carousel>
                    </Square>
                  </Flex>
                </Flex>
              </Flex>
            </HeroContent>
          </Wrapper>
        </HeroWrapper>
        <Feature />
        <Faq />
        <Footer />
      </div >
    </ThemeProvider >
  );
}

export default App;
