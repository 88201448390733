import React from 'react'
import { Flex } from './layout'
import { H2, Label } from './text'
import Logo from '../assets/images/logo.svg'
import discordLogo from '../assets/images/discord-logo.svg'
import twitterLogo from '../assets/images/twitter-logo.svg'
import { Image } from './msc'
import { Container, Row } from 'react-bootstrap'
import Community from './community'

const Footer = () => {
  return (
    <Flex pt={5} pb={5} backgroundColor="gradientDark" flexDirection="column" alignItems="center">
      <Container>
        <Row>
          <Flex justifyContent="center" flexDirection={["column", "row"]} alignItems="flex-start" pl={[3, 0]}>
            <Flex flex={2} flexDirection="column" justifyContent="space-between">
              <Flex flexDirection="column">
                <Flex alignItems="center">
                  <img
                    src={Logo}
                    width="60"
                    height="60"
                    className="d-inline-block align-top"
                    alt="Doji logo"
                  />
                  <H2 color="secondary" pl={2}>Doji x Ethereum</H2>
                </Flex>
                <Label color="white" pt={2}>2112 Unique Doji Cards</Label>
              </Flex>
            </Flex>
            <Flex flex={1} alignItems={["flex-start", "center"]} flexDirection="column" mt={[4, 0]}>
              <Label color="white" pb={2}>Join our community</Label>
              <Community />
            </Flex>
          </Flex>
        </Row>
      </Container>
      <Label pt={[4, 5]} color="gray">© 2021 Doji</Label>
    </Flex>
  )
}

export default Footer
