import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from 'styled-components'

import theme from './theme'
import './assets/styles/App.scss'

import App from "./App";
import Mint from "./Mint";
import NavBar from './components/nav-bar'
import { Box, Flex } from './components/layout'

const Main = () => {
  useEffect(() => {
    console.log('main component load')
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box position="absolute" top={[0, 4]} left={0} right={0}>
        <NavBar />
      </Box>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/mint" element={<Mint />} />
      </Routes>
    </ThemeProvider>
  );
}

export default Main;