import { useState } from 'react'
import styled from 'styled-components';
import useInterval from 'use-interval'
import { Flex } from './layout';
import { Label } from './text';

const freeMintTime = 'March 19 2022 16:00:00 UTC+0';
const publicMintTime = 'March 21 2022 16:00:00 UTC+0';

const NumberHolder = styled(Flex)`
  background: #9c9c9c17;
  border-radius: 20px;
  min-width: 100px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px 0px;
  color: #fff;
`;

const CountDown = () => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showPublic, setShowPublic] = useState(false);

  useInterval(() => {
    const t = getTimeRemaining(freeMintTime);
    if (t.total <= 0) {
      setShowPublic(true);
    }

    setHours(t.hours);
    setMinutes(t.minutes);
    setSeconds(t.seconds);
  }, 1000);

  const getTimeRemaining = (endtime) => {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
  }

  const renderNumberBlock = (number, unit) => (
    <NumberHolder flexDirection="column" alignItems="center">
      <Label fontSize={6}>{String(number).padStart(1, '0')}</Label>
      <Label fontSize={2}>{unit.toUpperCase()}</Label>
    </NumberHolder>
  )

  if (showPublic) {
    return null
  } else {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Flex mr={["-10px"]} ml={["-10px"]}>
          {renderNumberBlock(hours, 'hours')}
          {renderNumberBlock(minutes, 'minutes')}
          {renderNumberBlock(seconds, 'seconds')}
        </Flex>
        <Label color="secondary" fontSize={[1]} mb={[2, 5]} mt={2}>UNTIL PRESALE</Label>
      </Flex>
    )
  }
}

export default CountDown;
