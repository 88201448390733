import React from 'react'
import { Carousel, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { Label, SectionTitle } from './text'
import { Flex, Box, ResponsiveImg } from './layout'
import { Image } from './msc'
import VideoCarousel from './video-carousel'
import Logo2 from '../assets/images/logo2.svg'
import Card from '../assets/images/onecardspin.svg'
import blueprint1 from '../assets/images/blueprint1.png'
import blueprint2 from '../assets/images/blueprint21.png'
import preview1 from '../assets/images/features/preview1.mp4'
import preview2 from '../assets/images/features/preview2.mp4'
import preview3 from '../assets/images/features/preview3.mp4'
import doji from '../assets/images/features/doji.mp4'

const videos = [{
  video: doji,
  caption: 'DOJI Collections'
}, {
  video: preview2,
  caption: 'Vitalik Buterin had died in a car accident rumor',
}, {
  video: preview3,
  caption: 'ETH first hit USD4000'
}]

const PreviewVideo = styled.video`
  width: 100%;
`

const FeatureSection = () => {
  return (
    <Flex pl={[3, 0]} pr={[3, 0]} pt={[6, 7]} pb={[6, 7]} backgroundColor="gradientDark">
      <Container>
        <Flex mt={0} mb={[6, 7]} flexDirection={["column", "row"]}>
          <Flex flexDirection="column" flex={[3, 2]} pr={[0, 4, 6]}>
            <Flex mb={6} justifyContent="center">
              <ResponsiveImg src={Logo2} maxHeight={"150px"} />
            </Flex>
            <SectionTitle anchor="feature" title="2112 Unique Animated Cards" />
            <Label pt={[4, 5]} textStyle="subText">
              <Label color="secondary">Doji NFT</Label> is a collection of 2112 pieces of unique animated cards generated based on the historical Price data of <Label color="secondary">Ethereum</Label>.
            </Label>
            <Label pt={[4, 5]} textStyle="subText">
              Each card uses hourly candlestick chart to displays the high, low, open and close price of each trading day, from 21 Mar 2016 to 31 Dec 2021, a total of 2112 days.
            </Label>
          </Flex>
          <Flex alignItems="center" flex={1} justifyContent="center" mt={[5, 0]} mb={[2, 0]} display={["none", "flex"]}>
            <ResponsiveImg src={Card} maxWidth={["100%", "auto"]} />
          </Flex>
        </Flex>
        <Flex pt={6} flexDirection={["column", "row"]} flex={2}>
          <Flex flex={1} mr={6} mb={[6, 0]} width={["100%", "auto"]}>
            <Carousel>
              <Carousel.Item><Image width="100%" src={blueprint1} /></Carousel.Item>
              <Carousel.Item><Image width="100%" src={blueprint2} /></Carousel.Item>
            </Carousel>
          </Flex>
          <Flex flexDirection="column" pt={[3, 5]} flex={1}>
            <SectionTitle title="Candlestick charts" />
            <Label textStyle="subText" pt={[4, 5]}>Candlestick charts originated in Japan over 100 years before the West developed the bar and point-and-figure charts. In the 1700s, a Japanese man named Homma discovered that, while there was a link between price and the supply and demand of rice, the markets were strongly influenced by the emotions of traders.</Label>
            <Label textStyle="subText" pt={[4, 5]}>Candlesticks show that emotion by visually representing the size of price moves with different colors. Traders use the candlesticks to make trading decisions based on regularly occurring patterns that help forecast the short-term direction of the price.</Label>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}

export default FeatureSection
