import React from 'react'
import { Accordion, Container } from 'react-bootstrap'
import { typography } from 'styled-system'

import { SectionTitle, Label } from './text'
import { Flex } from './layout'
import styled from 'styled-components'

const FaqBody = styled(Accordion.Body)`
  font-family: Aldrich;
  font-weight: 600;
  white-space: pre-line;
`

const FaqHeader = styled(Accordion.Header)`
  button {
    font-family: Aldrich;
    font-weight: 600;
  }
`

const StyledAccordion = styled(Accordion)`
  .accordion-item {
    border-bottom: ${props => props.borderBottom ? `${props.theme.borders[props.borderBottom]} !important` : 'none'};
  }

  .accordion-item h2 button {
    padding: 1.25rem;
  }
`

const Faq = () => {
  const faq = [
    {
      question: 'What is Doji x Ethereum?',
      ans: 'Doji NFT is a collection of 2112 pieces of unique animated cards generated based on the historical price data of Ethereum.\nEach card uses hourly candlesticks chart to displays the high, low, open and close price of each trading day, from 21 Mar 2016 to 31 Dec 2021, a total of 2112 days.',
    },
    {
      question: 'How can I get a Doji x Ethereum?',
      ans: 'Doji x Ethereum will be available for purchase on our website through an initial sale. At the time of purchase, a randomly selected date of Doji x Ethereum will be minted on the blockchain and delivered to your wallet and you can view and trade on OpenSea & LooksRare.',
    },
    {
      question: 'How do I actually purchase a Doji x Ethereum',
      ans: 'Install a MetaMask extension in your browser and link your crypto wallet to it. Ensure you have enough ETH in your wallet to cover the cost of a Doji x Ethereum plus the associated gas fees. Approve the desired transaction on MetaMask and you are all set.'
    },
    {
      question: 'How much is Doji x Ethereum?',
      ans: 'Presale FREE + gas, Public sale 0.05ETH + gas',
    },
    {
      question: 'When will my Doji x Ethereum be revealed?',
      ans: 'All Doji x Ethereum will be revealed 72 hours after start of sale.'
    },
    {
      question: "What are your secondary royalty fees?",
      ans: '8.5%'
    },
    {
      question: 'What can I do with my Doji x Ethereum?',
      ans: 'You are free to do anything with them under a non-exclusive license.',
    },
  ]
  return (
    <Flex pt={[5, 6]} pb={[6, 7]} pl={2} pr={2}>
      <Container>
        <SectionTitle anchor="faq" title="FAQ" color="black" />
        {
          faq.map((f, i) => (
            <StyledAccordion defaultActiveKey="0" flush key={i}>
              <Accordion.Item key={i} eventKey={i}>
                <FaqHeader >{f.question}</FaqHeader>
                <FaqBody  ><Label dangerouslySetInnerHTML={{ __html: f.ans }} /></FaqBody>
              </Accordion.Item>
            </StyledAccordion>
          ))
        }
      </Container>
    </Flex>
  )
}

export default Faq