import "react-native-get-random-values"
import "@ethersproject/shims"
// import MetaMaskOnboarding from '@metamask/onboarding'
import detectEthereumProvider from '@metamask/detect-provider'

import React, { useState, useEffect, useMemo } from 'react'
import useInterval from 'use-interval'
import { Container, Row, Col, Button, Form, Spinner, Alert } from 'react-bootstrap'
import { ethers } from 'ethers'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import logo from '../assets/images/logo.svg'
import pattern from '../assets/images/pattern.svg'
import { Box, Flex } from './layout'
import { H2, Label } from './text'
import Community from "./community"
import AlertView from "./alert-view"
import MinusIcon from '../assets/images/minus-solid.svg'
import PlusIcon from '../assets/images/plus-solid.svg'
import Logo from '../assets/images/logo.svg'

const axios = require('axios').default;

const CONTRACT_ADDRESS = '0xA27416028dF9cA6dcbEad109D238c94dD2F37fc8';
const abi = require('./contract.json').abi;


const MintSection = styled(Flex)`
  color: white;
  flex: 1;
`

const Square = styled(Flex)`
  flex: 1 0 110%;

  :after {
    content: '';
    display: block;
    padding-bottom: 110%;
  }
`

const Stepper = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`

const StyledLogo = styled.img`
  cursor: pointer;
`

const Mint = () => {
  const [amount, setAmount] = useState(0)
  const [remains, setRemains] = useState(0)
  const [ethError, setEthError] = useState(null)
  const [accounts, setAccounts] = useState([])
  const [provider, setProvider] = useState(null)
  const [minting, setMiniting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [alertTitle, setAlertTitle] = useState("Something went wrong")
  const [alertrMessage, setAlertMessage] = useState(null)
  const [variant, setVariant] = useState("danger")
  const [loading, setLoading] = useState(false)
  const [signature, setSignature] = useState()
  const [freeMint, setFreeMint] = useState(false)
  const [saleLive, setSalesLive] = useState(false)
  const [mintMax, setMintMax] = useState(0)
  const [maxSupply, setMaxSupply] = useState(0)
  const [mintPrice, setMintPrice] = useState(0)
  const [freeMinted, setFreeMinted] = useState(0)
  const [freeMintMax, setFreeMintMax] = useState(0)

  useEffect(() => {
    async function connect() {
      const provider = await detectEthereumProvider({ timeout: 1000 })

      if (provider) {
        setProvider(provider)
      } else {
        // setEthError('Non-Ethereum browser detected. You should consider trying MetaMask!')
      }
    }

    connect()
  }, [])

  const clearState = () => {
    setSignature(null)
    setFreeMinted(0)
    setFreeMintMax(0)
    setMintMax(0)
  }

  // useEffect(() => {
  //   function handleNewAccounts(accounts) {
  //     setAccounts(accounts)
  //     clearState()
  //   }
  //   if (MetaMaskOnboarding.isMetaMaskInstalled()) {
  //     window.ethereum.on('accountsChanged', handleNewAccounts);
  //     return () => {
  //       window.ethereum.off('accountsChanged', handleNewAccounts);
  //     };
  //   }
  // }, []);

  useInterval(async () => {
    if (accounts && !isEmpty(accounts)) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner()
        const address = await signer.getAddress()

        const contract = new ethers.Contract(CONTRACT_ADDRESS, abi, signer)
        const freeMint = await contract.freeMintLive()
        setFreeMint(freeMint)
        const saleLive = await contract.saleLive()
        setSalesLive(saleLive)

        const maxSupply = await contract.MAX_SUPPLY()
        setMaxSupply(maxSupply.toNumber())

        const freeMinter = await contract.freeMinters(address)
        setFreeMinted(freeMinter.toNumber())

        if (!freeMint && !saleLive) {
          setEthError("Sale is not live")
          setMintMax(0)
          setAmount(0)
        }
        if (saleLive) {
          setEthError(null)
        }
        if (freeMint && signature) {
          setEthError(null)
        }

        const transaction = await contract.totalSupply()
        const remains = maxSupply.toNumber() - (transaction.toNumber())
        setRemains(remains)
        setMintPrice(remains <= 612 ? 0.05 : 0)

        const leftOver = remains - 612
        if (saleLive && (leftOver > 0 && leftOver <= 3)) {
          setMintMax(leftOver)
        } else if (saleLive) {
          // if (remains < 5) {
          //   setMintMax(remains)
          // } else {
          setMintMax(5)
          // }
        }
      } catch (e) {
        setAccounts([])
      }
    }
  }, 1000);

  useMemo(async () => {
    if (freeMint && accounts && !isEmpty(accounts)) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner()
        const signerAddress = await signer.getAddress()

        fetchSignature(signerAddress)
      } catch (e) {
        setAccounts([])
      }
    }
  }, [accounts, freeMint]);

  useEffect(() => {
    console.log(freeMintMax, freeMinted)
    if (freeMintMax > 0) {
      setMintMax(freeMintMax - freeMinted)
    }
  }, [freeMintMax, freeMinted])

  const fetchSignature = async (signerAddress) => {
    if (freeMint) {
      // Make a request for a user with a given ID
      setLoading(true)
      axios.get(`https://api.doji.dev/mint-signature/${signerAddress}`)
        .then(function (response) {
          // handle success
          console.log(response);
          setLoading(false);
          setEthError(null);
          setSignature(response.data.signature);
          setFreeMintMax(response.data.max);
        })
        .catch(function (error) {
          // handle error
          setLoading(false)
          setEthError("You don't have Free Mint Pass");
          setSignature(null);
          setFreeMintMax(0);
          console.log(error);
        })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (isEmpty(accounts)) {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
      setAccounts(accounts)

      return
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner()
    const signerAddress = await signer.getAddress()
    const contract = new ethers.Contract(CONTRACT_ADDRESS, abi, signer)

    // let signature;
    if (freeMint) {
      // Make a request for a user with a given ID
      // setLoading(true)
      fetchSignature(signerAddress)
      // try {
      //   const res = await axios.get(`https://api.doji.dev/mint-signature/${signerAddress}`)
      //   console.log(res);
      //   signature = res.data.signature;
      //   // response.data.signature;
      //   setLoading(false);
      //   setEthError(null);
      //   // setSignature(res.data.signature);
      // } catch (error) {
      //   setLoading(false)
      //   setEthError("You don't have Free Mint Pass");
      //   // setSignature(null);
      //   console.log(error);
      //   return
      // }
    }

    try {
      let mint;
      if (freeMint) {
        mint = await contract.connect(signer).mintFree(amount, freeMintMax, signature, { value: ethers.utils.parseEther("0") });
      }
      if (saleLive) {
        mint = await contract.connect(signer).mintPublic(amount, { value: ethers.utils.parseEther(`${amount * mintPrice}`) });
      }
      setMiniting(true);
      await mint.wait()
      setAlertTitle('Congratulations!')
      setAlertMessage(`You have successfully minted ${amount} x DOJI NFT!`);
      setVariant('success')
      setAmount(1)
      setShowModal(true)
      setMiniting(false);
    } catch (e) {
      setAlertTitle('Something went wrong')
      setAlertMessage('')
      setVariant('danger')
      setMiniting(false);
      if (e.code === 4001) {
        setAlertMessage('User rejected the transaction')
      } else if (e.code === 'INSUFFICIENT_FUNDS' || (e.code === -32000 && /insufficient funds/.test(e.message))) {
        setAlertMessage("You don't have enough ETH to mint")
      } else if (/FREE_CLOSED/.test(e.message)) {
        setAlertMessage('Free mint is closed.')
      } else if (/FREE_MAX_EXCEEDED/.test(e.message)) {
        const freeRemains = remains - 612;
        if (freeRemains > 0) {
          setAlertMessage(`Only ${freeRemains} free mint left.`)
        } else {
          setAlertMessage('Free mint sold out.')
        }
      } else if (/NOT_WHITELISTED/.test(e.message)) {
        setAlertMessage("You don't have Free Mint Pass")
      } else if (/FREE_MINTER_MAX_EXCEEDED/.test(e.message)) {
        setAlertMessage("You've reached the maximum amount of free mints.")
      } else if (/SALE_CLOSED/.test(e.message)) {
        setAlertMessage("Sale is closed.")
      } else if (/ALL_SOLD_OUT/.test(e.message)) {
        if (remains > 0) {
          setAlertMessage(`Only ${remains} DOJI NFTs left for mint.`)
        } else {
          setAlertMessage('All NFTs are sold out.')
        }
      } else if (/SOLD_OUT_RESERVE/.test(e.message)) {
        setAlertMessage('All NFTs are sold out.')
        // } else if (/NOT_ENOUGH_ETH/.test(e.message)) {
        //   setAlertMessage('')
      } else if (/TRANSACTION_MAX_EXCEEDED/.test(e.message)) {
        setAlertMessage('Maximum 5 mints per transactions.')
      } else if (/CANNOT_MINT_NONE/.test(e.message)) {
        setAlertMessage('You have to mint at least 1 NFT.')
      } else {
        setAlertTitle(`Something went wrong, please try again.`)
      }
    }

    setShowModal(true)
    setTimeout(() => {
      setShowModal(false)
    }, 5000);
  }

  const renderButton = () => {
    if (ethError) {
      return null;
    }

    if (!window.ethereum) {
      return "Please install MetaMask"
    }

    let content = null;

    // Loading the whitelist / loading data from blockchain
    if (loading || (!isEmpty(accounts) && maxSupply === 0)) {
      content = (
        <Spinner animation="border" size="sm" role="status">
          <span className="visually-hidden">Checking whitelist...</span>
        </Spinner>
      );
    } else {
      content = !minting && (isEmpty(accounts) ? 'Connect wallet' : 'Mint');
      if (minting) {
        content = (
          <React.Fragment>
            <span>Minting&nbsp;</span>
            <Spinner animation="border" size="sm" role="status">
              <span className="visually-hidden">Minting...</span>
            </Spinner>
          </React.Fragment>
        );
      }
    }

    return (
      <Button style={{ borderRadius: "15px" }} variant="primary" type="submit" size="lg" disabled={minting || loading}>
        {content}
      </Button>
    );
  }

  return (
    <MintSection pt={[0, 100]} pb={[5, 6]} flexDirection="column">
      <Flex>
        <Container>
          <Flex flexDirection="column" alignItems="center">
            <Flex flex="1">
              <Flex flexDirection="column" justifyContent="center" height="100%" pb={[5, 0]}>
                <H2 pt={5} pb={2} fontSize={[5, 6]} id="mint" textStyle="title">Mint your<br />Doji x Ethereum</H2>
                <Flex><Box width="100px"><Label textStyle="subText" fontWeight={1} pb={2}>Presale:</Label></Box><Label textStyle="subText" fontWeight={1} pb={2}>19 Mar, 2022 1600UTC</Label></Flex>
                <Flex><Box width="100px"><Label textStyle="subText" fontWeight={1} pb={2}>Public:</Label></Box><Label textStyle="subText" fontWeight={1} pb={2}>LIVE</Label></Flex>
                <Community opensea={false} />
              </Flex>
            </Flex>
            <Flex flex="1" mt={4}>
              <Box border="card" borderRadius="card">
                <Form onSubmit={onSubmit}>
                  <Flex>
                    <Flex width={["100%", "400px"]} height="400px" p={[3]} backgroundColor="primary">
                      <Flex flexDirection="column" flex={1} justifyContent="space-between">
                        <Box>
                          <Label pt={5} pb={2} fontSize={[2, 3]} id="mint" textStyle="title">Mint your<br />Doji x Ethereum</Label>
                          <br />
                          <Label fontSize={[2, 3]}>PUBLIC SALE</Label>
                          <Flex flex={1} justifyContent="center" mb={2} mt={3}><Label fontSize={[4]} color="orange">{`${remains}`}/2112</Label></Flex>
                        </Box>

                        <Flex p={[3]} backgroundColor="rgba(255, 255, 255, 0.2)" borderRadius="card">
                          <Flex flex={1} alignItems="center" pr={2}>
                            <Box width="100%"><img src={logo} role="presentation" width="100%" /></Box>
                          </Flex>
                          <Flex flex={4} justifyContent="flex-end">
                            <Flex flexDirection="column">
                              <Flex flex={1} justifyContent="flex-end"><Label fontSize={[0, 0, 3]}>Price per Doji</Label></Flex>
                              <Flex flex={1} justifyContent="flex-end"><Label fontSize={[3, 3, 4]}>{mintPrice === 0 ? 'FREE' : `${mintPrice}ETH`}</Label></Flex>
                            </Flex>
                          </Flex>
                        </Flex>

                        <Box>
                          <Flex key="mint-1">
                            <Flex justifyContent="space-between" flex="1">
                              <Flex alignItems="center">
                                <Stepper src={MinusIcon} onClick={() => mintMax === 0 ? setAmount(0) : amount <= 1 ? setAmount(1) : setAmount(amount - 1)} />
                                <Label ml={3} mr={3} fontSize={3}>{amount}</Label>
                                <Stepper src={PlusIcon} onClick={() => amount >= mintMax ? setAmount(mintMax) : setAmount(amount + 1)} />
                              </Flex>
                              <Label fontSize={3}>Max {mintMax}</Label>
                            </Flex>
                          </Flex>
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex flexDirection="column" flex={1} pt={2} backgroundColor="white">
                    <Flex flexDirection="row" alignItems="center" color="black" mt={2} mb={2}>
                      <Flex flex={1}><Label fontSize={3}>Total</Label></Flex>
                      <Flex flex={1} justifyContent="flex-end"><Label fontSize={3}>{Math.round(amount * mintPrice * 100) / 100}ETH</Label></Flex>
                    </Flex>
                    <Flex className="d-grid gap-2">
                      {renderButton()}
                      {ethError &&
                        <Label color="blue" fontSize={[0, 19]} fontFamily="subText" fontWeight={2} textAlign="center">{ethError}</Label>
                      }
                    </Flex>
                  </Flex>
                </Form>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Flex>
    </MintSection>
  )
}

export default Mint
