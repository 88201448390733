import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { SiDiscord, SiTwitter } from 'react-icons/si';

import styled from 'styled-components'

import { Flex, Box } from './layout'
import { SectionTitle, Label } from './text'
import VM from '../assets/images/5092.gif'
import MAYC from '../assets/images/mayc.png'
import BAKC from '../assets/images/BAKC.png'
import Fluff from '../assets/images/unnamed0.jpg'
import { Link } from './msc'

const ImageWrapper = styled(Flex)`
  border-radius: 200px;
  overflow: hidden;
  width: 250px;
  hegiht: 250px;
  align-self: center;
`;

const Team = () => {
  return (
    <Flex pt={[5, 6]} pb={[6, 7]} pl={2} pr={2} backgroundColor="gradientDark">
      <Container>
        <SectionTitle anchor="team" title="Team" />
        <Label textStyle="subText">
          We all love ETH and NFT, that's why we build this collection for all ETH and NFT lovers to memorize their special moments in crypto trading
        </Label>
        <Box mt={6} />
        <Row>
          <Col xs={12} md={4}>
            <Flex pt={[4, 5]} flexDirection="column">
              <ImageWrapper mb={5}>
                <img src={VM} width="250px" />
              </ImageWrapper>
              <Flex alignItems="center"><Label textStyle="subText">Founder</Label><Link href="https://twitter.com/doji" target="_blank" rel="noreferrer" fontSize={2} ml={4} mt={-8}><SiTwitter /></Link></Flex>
              <Label textStyle="subText">
                Aaron is a tech entrepreneur in various development and product management roles. During the pandemic, he spent a lot of time investigating Ethereum, DeFi, and NFTs.
              </Label>
            </Flex>
          </Col>

          <Col xs={12} md={4}>
            <Flex pt={[4, 5]} flexDirection="column">
              <ImageWrapper mb={5}>
                <img src={MAYC} width="250px" />
              </ImageWrapper>
              <Flex alignItems="center"><Label textStyle="subText">Dev</Label><Link href="https://twitter.com/tofufighter" target="_blank" rel="noreferrer" fontSize={2} ml={4} mt={-8}><SiTwitter /></Link></Flex>
              <Label textStyle="subText">
                Joe is a experience Full Stack developer participated in various buzzword technologies, machine learning, AI, IoT, etc. Now focusing on Web3 development.
              </Label>
            </Flex>
          </Col>

          <Col xs={12} md={4}>
            <Flex pt={[4, 5]} flexDirection="column">
              <ImageWrapper mb={5}>
                <img src={Fluff} width="250px" />
              </ImageWrapper>
              <Flex alignItems="center"><Label textStyle="subText">Marketer</Label><Link href="https://twitter.com/gowaffle_eth" target="_blank" rel="noreferrer" fontSize={2} ml={4} mt={-8}><SiTwitter /></Link></Flex>
              <Label textStyle="subText">
                Daniel is our marketer to help us spread the word out for this project, always benchmarks the performance of our promotion campaign.
              </Label>
            </Flex>
          </Col>
        </Row>
      </Container>
    </Flex>
  )
}

export default Team
