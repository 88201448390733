import styled from 'styled-components'
import { color, space, typography, position, textStyle, layout } from 'styled-system'
import { Flex } from './layout'

const H1 = styled.h1`
  padding: 0px;
  margin: 0px;
  font-size: 2.5rem;
  ${space}
  ${color}
  ${position}
  ${typography}
  ${textStyle}
`

const H2 = styled.h2`
  padding: 0px;
  margin: 0px;
  font-size: 2rem;
  ${space}
  ${color}
  ${position}
  ${typography}
  ${textStyle}
`

const Label = styled.span`
  ${layout}
  ${space}
  ${color}
  ${position}
  ${typography}
  ${textStyle}
`

const SectionTitle = ({ title, anchor, color = 'secondary' }) => (
  <Flex justifyContent="flex-start"><H2 pt={5} pb={5} fontSize={[3, 5]} fontWeight={700} id={anchor} color={color} fontFamily="title">{title}</H2></Flex>
)

export {
  H1,
  H2,
  Label,
  SectionTitle,
}