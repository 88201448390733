import React from 'react'
// import Slider from 'react-slick'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
// Import Swiper React components
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Import Swiper styles
import 'swiper/swiper.scss';

import styled from 'styled-components'
import { Box } from './layout';
import { Label } from './text';

const PreviewVideo = styled.video`
  width: 100%;
`

const SlideWrapper = styled(Box)`
  position: relative;
`

const Caption = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
  padding: 20px;
`

const VideoCarousel = ({ sources }) => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      modules={[Autoplay]}
      autoplay={{ delay: 15000 }}
      breakpoints={{
        600: {
          slidesPerView: 3,
        },
      }}
    >
      {
        sources.map(src => (
          <SwiperSlide>
            <SlideWrapper>
              <Caption><Label color="white">{src.caption}</Label></Caption>
              <PreviewVideo autoPlay loop muted playsInline>
                <source src={src.video} type="video/mp4" />
              </PreviewVideo>
            </SlideWrapper>
          </SwiperSlide>
        ))
      }
    </Swiper>
  )
}

export default VideoCarousel
