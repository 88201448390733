import styled from 'styled-components'
import { border, space, layout, position, typography } from 'styled-system'

const Link = styled.a`
  ${space}
  ${typography}

  color: white;
  :hover {
    color: ${props => props.theme.colors.secondary} !important;
  }
`

const Image = styled.img`
  ${space}
  ${layout}
  ${position}
  ${border}
`

export {
  Image,
  Link,
}
