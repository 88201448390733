import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { Container } from 'react-bootstrap'
import { Box, Flex } from './components/layout'

import theme from './theme'
import './assets/styles/App.scss'

import Logo from './assets/images/logo.svg'

import Mint from './components/mint'

const Bg = styled(Flex)`
  background: linear-gradient(180deg, #536976, #292e49);
`

const StyledLogo = styled.img`
  cursor: pointer;
`

const MintPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Bg minHeight="100vh" width="100vw" flexDirection="column">
        <Mint />
      </Bg>
    </ThemeProvider>
  );
}

export default MintPage;