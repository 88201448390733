import React, { useEffect, useMemo } from 'react'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Logo from '../assets/images/logo.svg'

const StyledNav = styled(Nav)`
  width: 100%;
  justify-content: flex-start;
`

const StyledNavLink = styled(Nav.Link)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 24px;

  @media screen and (min-width: 40em) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
`

const StyledRRLink = styled(Link)`
  text-decoration: none;
  padding: 0.5rem 0rem 1rem 0rem;
  font-size: 24px;
  color: white;

  :hover {
    color: rgba(255, 255, 255, 0.75);
  }

  @media screen and (min-width: 40em) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
`

const StyledNavbar = styled(Navbar)`
  z-index: 999;
`

const NavBar = () => {
  const [isLanding, setIslanding] = React.useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    setIslanding(pathname === '/')
  }, [pathname]);

  return (
    <StyledNavbar bg="transparent" expand="md" variant="dark">
      <Container>
        <Navbar.Brand href="#">
          <Link to="/">
            <img
              src={Logo}
              width="60"
              height="60"
              className="d-inline-block align-top"
              alt="Doji logo"
            />
          </Link>
        </Navbar.Brand>
        {isLanding &&
          <React.Fragment>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <StyledNav className="me-auto">
                <StyledRRLink to="/mint">Mint</StyledRRLink>
                <StyledNavLink href="#feature">Feature</StyledNavLink>
                <StyledNavLink href="#faq">FAQ</StyledNavLink>
              </StyledNav>
            </Navbar.Collapse>
          </React.Fragment>
        }
      </Container>
    </StyledNavbar>
  )
}

export default NavBar
